import React from 'react';

const LinkedInPine = ({
    xmlns='http://www.w3.org/2000/svg',
    width = 20,
    height = 20,
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns={xmlns}>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.125 0H16.875C18.6009 0 20 1.39911 20 3.125V16.875C20 18.6009 18.6009 20 16.875 20H3.125C1.39911 20 0 18.6009 0 16.875V3.125C0 1.39911 1.39911 0 3.125 0ZM3.99955 7.92458V16.1553H6.61494V7.92458H3.99955ZM3.8457 5.30919C3.8457 6.15535 4.46109 6.77073 5.30724 6.77073C6.1534 6.77073 6.76878 6.15535 6.76878 5.30919C6.76878 4.46304 6.1534 3.84766 5.30724 3.84766C4.53801 3.84766 3.8457 4.46304 3.8457 5.30919ZM13.538 16.1553H15.9996V11.0784C15.9996 8.53996 14.4611 7.69381 12.9995 7.69381C11.6919 7.69381 10.7688 8.53996 10.538 9.07843V7.92458H8.07647V16.1553H10.6919V11.7707C10.6919 10.6169 11.4611 10.0015 12.2303 10.0015C12.9995 10.0015 13.538 10.3861 13.538 11.6938V16.1553Z" fill="#124A2C"/>
    </svg>
);

export default LinkedInPine;