import React from 'react';

const TwitterPine = ({
    xmlns='http://www.w3.org/2000/svg',
    width = 24,
    height = 24,
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns={xmlns}>
        <path d="M22.1603 5.65593C21.3969 5.99362 20.5873 6.2154 19.7583 6.31393C20.6321 5.79136 21.2861 4.96894 21.5983 3.99993C20.7783 4.48793 19.8793 4.82993 18.9423 5.01493C18.313 4.34151 17.4787 3.89489 16.5693 3.74451C15.6599 3.59413 14.7263 3.74842 13.9136 4.18338C13.1009 4.61834 12.4547 5.30961 12.0755 6.14972C11.6962 6.98983 11.6051 7.93171 11.8163 8.82893C10.1535 8.74558 8.52668 8.31345 7.04163 7.56059C5.55659 6.80773 4.24647 5.75097 3.19634 4.45893C2.82463 5.09738 2.6293 5.82315 2.63034 6.56193C2.63034 8.01193 3.36834 9.29293 4.49034 10.0429C3.82635 10.022 3.17698 9.84271 2.59634 9.51993V9.57193C2.59654 10.5376 2.93071 11.4735 3.54219 12.221C4.15367 12.9684 5.00483 13.4814 5.95134 13.6729C5.33496 13.84 4.68865 13.8646 4.06134 13.7449C4.32821 14.5762 4.84835 15.3031 5.54894 15.824C6.24952 16.3449 7.09547 16.6337 7.96834 16.6499C7.10082 17.3313 6.10752 17.8349 5.04522 18.1321C3.98293 18.4293 2.87247 18.5142 1.77734 18.3819C3.68904 19.6114 5.91444 20.264 8.18734 20.2619C15.8803 20.2619 20.0873 13.8889 20.0873 8.36193C20.0873 8.18193 20.0823 7.99993 20.0743 7.82193C20.8932 7.23009 21.5999 6.49695 22.1613 5.65693L22.1603 5.65593Z" fill="#124A2C"/>
    </svg>
);

export default TwitterPine;