import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { colors } from '../../styles/_colors';
import SunnysidePineLogo from '../../../public/icons/sunnyside/sunnyside-logo-pine';
import InstagramPine from '../../../public/icons/sunnyside/instagram-pine';
import TwitterPine from '../../../public/icons/sunnyside/twitter-pine';
import LinkedInPine from '../../../public/icons/sunnyside/linkedin-pine';
import FacebookPine from '../../../public/icons/sunnyside/facebook-pine';
import EmailPine from '../../../public/icons/sunnyside/email-pine';
import MaxWidthBox from '../max_width_box';
import Divider from '../divider';
import SunnysideLink from '../link';
import { FooterLinkBox, FooterYellowBox } from '.';
import useWindowSize from '../../utils/useWindowSize';
import { Typography, useTheme } from '@mui/material';
import { diagnosticLink } from '../../utils/diagnosticLinks';
import NewsPine from '../../../public/icons/sunnyside/news-pine';
import { PrismicFooterGenericItem, PrismicFooterHeader, PrismicSlice } from '../../utils/prismic/types/prismic-slice.type';

interface DesktopFooterProps {
    useCases: PrismicSlice;
    featuredArticles: PrismicSlice;
    noDivider?: boolean;
}

export default function DesktopFooter(props: DesktopFooterProps): JSX.Element {
    const { useCases, featuredArticles, noDivider } = props;
    const { width } = useWindowSize();
    const theme = useTheme();

    const useCasesItems = useCases.items as PrismicFooterGenericItem[];
    const useCasesPrimary = useCases.primary as PrismicFooterHeader;
    const featuredArticlesItem = featuredArticles.items as PrismicFooterGenericItem[];
    const featuredArticlesPrimary = featuredArticles.primary as PrismicFooterHeader;

    const LinkBox = styled(Box)<BoxProps>(({ theme }) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '2rem',
        '& a': {
            fontSize: '0.625rem !important',
            lineHeight: '1.25rem !important',
        }
    }));
    const DescriptorBox = styled(Box)<BoxProps>(({ theme }) => ({
        '& a': {
            lineHeight: '1.25rem !important',
        }
    }));

    return (
        <MaxWidthBox>
            {!noDivider && <Divider />}
            <Box width='100%'
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                padding={width > 1200 ? '2.5rem 6rem 5rem 6rem' : '2.5rem 0rem 5rem 0rem'}>
                <DescriptorBox>
                    <SunnysidePineLogo width='122' height='39' />
                    <Typography
                        variant='footer_text'
                        paddingLeft='0rem'
                        lineHeight='1.25rem !important'
                        width='16rem'>
                        Sunnyside is a system for creating a
                        more mindful approach to drinking
                        to help you reach your goals. Our
                        members are seeing major benefits,
                        including a reduction in weekly
                        drinks, improved sleep, a healthier
                        diet, money saved, and an overall
                        improved sense of well-being.
                        <br /><br />
                        Sunnyside is not designed to
                        treat alcohol use disorder (AUD). For
                        resources related to AUD, including
                        how to get support, please visit the&nbsp;
                        <SunnysideLink
                            href='https://www.nih.gov'
                            fontVariant='footer_text'
                            underline
                            external>
                            NIH
                        </SunnysideLink>
                        &nbsp;website. We&apos;ve also partnered with&nbsp;
                        <SunnysideLink
                            href='https://moderation.org'
                            fontVariant='footer_text'
                            underline
                            external>
                            Moderation Management
                        </SunnysideLink>
                        , a non-profit dedicated to reducing the
                        harm caused by the misuse of alcohol.
                    </Typography>
                </DescriptorBox>
                <FooterLinkBox>
                    <Typography variant='footer_heading'>Explore</Typography>
                    <SunnysideLink href="/">Home</SunnysideLink>
                    <SunnysideLink href="/blog">Blog</SunnysideLink>
                    <SunnysideLink href="/reviews">Reviews</SunnysideLink>
                    <SunnysideLink href="/about">About</SunnysideLink>
                    <SunnysideLink external href="https://help.sunnyside.co">Help &amp; Support</SunnysideLink>
                    <SunnysideLink external href="https://get.sunnyside.co/organizations">For Organizations</SunnysideLink>
                    <SunnysideLink href="/partners">Partner with us</SunnysideLink>
                    <SunnysideLink href="/affiliates">Become an affiliate</SunnysideLink>
                    <SunnysideLink href={diagnosticLink()}>Sign Up</SunnysideLink>
                </FooterLinkBox>
                <Box height='18rem'
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'>
                    <Typography variant='footer_heading'>Get in Touch</Typography>
                    <Box display='flex'
                        alignItems='center'
                        justifyContent='flex-start'>
                        <InstagramPine width={24} height={24} />
                        <SunnysideLink external href='https://www.instagram.com/joinsunnyside/' padding='0rem'>
                            <Typography variant='footer_text' paddingLeft='0.5rem' className='link'>Instagram</Typography>
                        </SunnysideLink>
                    </Box>
                    <Box display='flex'
                        alignItems='center'>
                        <TwitterPine width={24} height={24} />
                        <SunnysideLink external href='https://twitter.com/joinsunnyside' padding='0rem'>
                            <Typography variant='footer_text' paddingLeft='0.5rem' className='link'>Twitter</Typography>
                        </SunnysideLink>
                    </Box>
                    <Box display='flex'
                        alignItems='center'>
                        <LinkedInPine width={24} height={24} />
                        <SunnysideLink external href='https://www.linkedin.com/company/join-sunnyside/' padding='0rem'>
                            <Typography variant='footer_text' paddingLeft='0.5rem' className='link'>LinkedIn</Typography>
                        </SunnysideLink>
                    </Box>
                    <Box display='flex'
                        alignItems='center'>
                        <FacebookPine width={24} height={24} />
                        <SunnysideLink external href='https://www.facebook.com/joinsunnyside' padding='0rem'>
                            <Typography variant='footer_text' paddingLeft='0.5rem' className='link'>Facebook</Typography>
                        </SunnysideLink>
                    </Box>
                    <Box display='flex'
                        alignItems='center'>
                        <EmailPine width={24} height={24} />
                        <SunnysideLink href='mailto:hello@sunnyside.co' padding='0rem'>
                            <Typography variant='footer_text' paddingLeft='0.5rem' className='link'>Contact Us</Typography>
                        </SunnysideLink>
                    </Box>
                    <Box display='flex'
                        alignItems='center'>
                        <NewsPine width={24} height={24} />
                        <SunnysideLink href='mailto:press@sunnyside.co' padding='0rem'>
                            <Typography variant='footer_text' paddingLeft='0.5rem' className='link'>Contact Press</Typography>
                        </SunnysideLink>
                    </Box>
                </Box>
                {useCases &&
                    <FooterLinkBox>
                        <Typography variant='footer_heading'>{useCasesPrimary.header_title[0].text}</Typography>
                        {useCasesItems.map((useCase, index) => (
                            <SunnysideLink
                                href={useCase.url.url}
                                key={index}>
                                {useCase.title}
                            </SunnysideLink>
                        ))}
                    </FooterLinkBox>
                }
                {featuredArticles &&
                    <Box>
                        <Typography variant='footer_heading'>{featuredArticlesPrimary.header_title[0].text}</Typography>
                        {featuredArticlesItem.map((article, index) => (
                            <FooterYellowBox
                                key={index}
                                marginTop='1rem'>
                                <SunnysideLink
                                    href={article.url.url}
                                    padding='0rem'>
                                    <Typography
                                        variant='footer_text'
                                        paddingLeft='0.5rem'
                                        lineHeight='1rem !important'
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '100%',
                                            },
                                            [theme.breakpoints.up('sm')]: {
                                                maxWidth: '15rem',
                                            },
                                        }}>
                                        {article.title}
                                    </Typography>
                                </SunnysideLink>
                            </FooterYellowBox>
                        ))}
                    </Box>
                }
            </Box>
            <Divider />
            <LinkBox>
                <Typography variant='footer_terms_text'>Copyright {new Date().getFullYear()} Cutback Coach, Inc. All rights reserved.</Typography>
                <Typography variant='footer_terms_text'><SunnysideLink href='/privacy' color={colors.dark_gray}>Privacy Policy</SunnysideLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                    <SunnysideLink href='/terms' color={colors.dark_gray}>Terms &amp; Conditions</SunnysideLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                    <SunnysideLink href='/cookies' color={colors.dark_gray}>Cookie Policy</SunnysideLink></Typography>
            </LinkBox>
        </MaxWidthBox>
    );
}
