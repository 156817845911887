import React from 'react';

const EmailPine = ({
    xmlns='http://www.w3.org/2000/svg',
    width = 20,
    height = 20,
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns={xmlns} style={{ minWidth: width }}>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.008 13.1781C11.8996 13.1781 13.433 11.6447 13.433 9.75311C13.433 7.86154 11.8996 6.32812 10.008 6.32812C8.11642 6.32812 6.58301 7.86154 6.58301 9.75311C6.58301 11.6447 8.11642 13.1781 10.008 13.1781Z" stroke="#124A2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.9542 16.9845C11.6329 19.2622 7.18658 18.9817 4.17776 16.3048C1.16894 13.628 0.373009 9.24445 2.24868 5.68067C4.12435 2.11689 8.18829 0.291208 12.0983 1.25582C16.0083 2.22043 18.7568 5.72676 18.7598 9.754V10.5151C18.7598 11.9863 17.5671 13.179 16.0959 13.179C14.6247 13.179 13.432 11.9863 13.432 10.5151V9.754" stroke="#124A2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default EmailPine;