import { Typography, Box } from '@mui/material';

import SunnysideButton from '../button';
import { colors } from '../../styles/_colors';

interface PromotionButtonProps {
    buttonText: string,
    buttonUrl: string,
    promotionText: string,
    promotionColor?: string,
    borderRadius?: string,
    passQueryToUrl?: boolean
}

export default function PromotionButton(props: PromotionButtonProps): JSX.Element {
    const { buttonText, buttonUrl, promotionText, promotionColor, borderRadius, passQueryToUrl } = props;
    return (
        <Box display='flex'
            flexDirection='column'
            alignItems='center'
            width='fit-content'>
            <SunnysideButton 
                url={buttonUrl}
                passQueryToUrl={passQueryToUrl}
                borderRadius={borderRadius}>
                {buttonText}
            </SunnysideButton>
            <Typography variant='microcopy'
                color={promotionColor || colors.pine}
                paddingTop='1rem'>
                {promotionText}
            </Typography>
        </Box>
    );
}
