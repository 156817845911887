import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

import { colors } from '../../styles/_colors';
import DesktopFooter from './desktop_footer';
import MobileFooter from './mobile_footer';
import useWindowSize from '../../utils/useWindowSize';
import SunnysideGrid, { SunnysideGridProps } from '../grid';
import { getFooterData } from '../../logic/api';

export const FooterLinkBox = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '& a': {
        fontSize: '0.875rem !important',
        lineHeight: '2.25rem !important',
        padding: '0rem !important'
    }
}));
export const FooterYellowBox = styled(Box)<BoxProps>(({ theme }) => ({
    borderLeft: `7px solid ${colors.sunny}`,
    height: 'fit-content',
    display: 'flex',
    marginBottom: '1.5rem'
}));

interface SunnysideFooterProps {
    noDivider?: boolean
}

export default function SunnysideFooter(props: SunnysideFooterProps): JSX.Element {
    const { noDivider } = props;
    const [footerData, setFooterData] = useState<any>();
    const { width } = useWindowSize();

    const ChildGrid = styled(SunnysideGrid)<SunnysideGridProps>(({ theme }) => ({
        background: colors.beige,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '0rem !important'
    }));

    const getData = async() => {
        const data = await getFooterData();
        setFooterData(data);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <ChildGrid container item xs={6} sm={12} role='footer'>
            {width > 1050 && footerData &&
                <DesktopFooter 
                    useCases={footerData.useCases} 
                    featuredArticles={footerData.featuredArticles}
                    noDivider={noDivider}
                />}
            {width < 1050 && footerData &&
                <MobileFooter 
                    useCases={footerData.useCases} 
                    featuredArticles={footerData.featuredArticles}
                    noDivider={noDivider}
                />}
        </ChildGrid>    
    );
}